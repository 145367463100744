import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Benchmark } from '@wam/shared';
import { ApiGatewayService } from '@wam/authentication';
import { map } from 'rxjs/operators';
import { isNil } from 'lodash-es';
import { ResponseBenchmark } from './response-benchmark';
import { DateTransformer } from '../date-transformer.data';

@Injectable({
  providedIn: 'root',
})
export class AssessmentsService {
  constructor(private apiGatewayService: ApiGatewayService) {}

  getBenchmarks(organization: string, entity: string): Observable<Benchmark[]> {
    return this.apiGatewayService
      .get(`assessment/v3/orgs/${organization}/entities/${entity}/benchmarks`)
      .pipe(map((benchmarks: ResponseBenchmark[]) => this.transform(benchmarks)));
  }

  getResetDryRun(organization: string, entity: string, benchmarkId: string) {
    return this.apiGatewayService.simpleGet(
      `assessment/v3/orgs/${organization}/entities/${entity}/benchmarks/${benchmarkId}/reset/dry-run`,
    );
  }

  saveBenchmark(organization: string, entity: string, benchmark: Benchmark) {
    if (benchmark.entityBenchmark) {
      return this.apiGatewayService.put(
        `assessment/v3/orgs/${organization}/entities/${entity}/benchmarks/${benchmark.id}`,
        this.requestObject(benchmark),
        'entityBenchmarks',
      );
    } else {
      return this.apiGatewayService.patch(
        `assessment/v3/orgs/${organization}/entities/${entity}/benchmarks/${benchmark.id}`,
        {},
        'entityBenchmarks',
      );
    }
  }

  resetWacsBenchmark(organization: string, entity: string, benchmarkId: string) {
    return this.apiGatewayService.post(
      `assessment/v3/orgs/${organization}/entities/${entity}/benchmarks/${benchmarkId}/reset`,
      {},
    );
  }

  private requestObject(benchmark: Benchmark): Object {
    const startDate = benchmark.entityStartDate;
    const startMonth = (startDate.getMonth() + 1).toString().padStart(2, '0');
    const startDay = startDate.getDate().toString().padStart(2, '0');

    const endDate = benchmark.entityEndDate;
    const endMonth = (endDate.getMonth() + 1).toString().padStart(2, '0');
    const endDay = endDate.getDate().toString().padStart(2, '0');

    return {
      sessionTime: benchmark.sessionTime * 60,
      entityStartDate: `${startDate.getFullYear()}-${startMonth}-${startDay}`,
      entityEndDate: `${endDate.getFullYear()}-${endMonth}-${endDay}`,
    };
  }

  private transform(benchmarks: ResponseBenchmark[]): Benchmark[] {
    return benchmarks.map((b) => {
      const bm = {} as Benchmark;
      bm.startDate = DateTransformer.transform(b.startDate);
      bm.endDate = DateTransformer.transform(b.endDate);
      bm.id = b.id;
      bm.entityBenchmark = b.entityBenchmark;
      bm.sessionTime = b.sessionTime;
      bm.startDate = DateTransformer.transform(b.startDate);
      bm.endDate = DateTransformer.transform(b.endDate);
      if (!isNil(b.entityStartDate)) {
        bm.entityStartDate = DateTransformer.transform(b.entityStartDate);
      }
      if (!isNil(b.entityEndDate)) {
        bm.entityEndDate = DateTransformer.transform(b.entityEndDate);
      }
      return bm;
    });
  }
}

import { combineLatest, forkJoin, Observable, of } from 'rxjs';
import {
  AnalyticsSchool,
  ClassroomAnalytics,
  documentId,
  Facets,
  LevelLocation,
  MetricTarget,
  Product,
  ProductLocation,
  State,
  StudentAnalytics,
  ViewLevel,
  WelGoal,
} from '@wam/shared';
import { catchError, map, mergeMap, reduce, switchMap, take } from 'rxjs/operators';
import { ApiGatewayService } from '@wam/authentication';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as fromGoals from './state/goals.selectors';
import { AnalyticsQuery } from '@app/shared/analytics-query.model';
import { AssignmentsService } from '@app/shared/assignments.service';
import { AnalyticsService } from '@app/shared/services/analytics.service';
import { WithAuthService } from '@app/shared/services/with-auth.service';
import { Course } from '@app/shared/course.model';
import { isArray, isEmpty, omit } from 'lodash-es';
import { AssignmentBatchItemResponse, AssignmentResponse } from '@wap/catalog-v5';

type ChangeAssignmentOutput = {
  failures?: AssignmentBatchItemResponse[];
  successes: AssignmentResponse[];
};

@Injectable({
  providedIn: 'root',
})
export class GoalsService extends WithAuthService {
  constructor(
    private apiGatewayService: ApiGatewayService,
    private assignmentsService: AssignmentsService,
    private analyticsService: AnalyticsService,
    protected store: Store<State>,
  ) {
    super(store);
  }

  getHits(): Observable<
    [ClassroomAnalytics[] | StudentAnalytics[] | AnalyticsSchool[], number, Facets]
  > {
    return combineLatest([
      this.store.pipe(
        select(fromGoals.getSelectedFacets),
        map((selected) => AnalyticsQuery.fromFacets(this.removeUnassignedStudents(selected))),
      ),
      this.store.pipe(select(fromGoals.getPage)),
      this.store.pipe(select(fromGoals.getLevel)),
    ]).pipe(
      switchMap(([query, page, level]) => {
        switch (level) {
          case ViewLevel.schools:
            return this.analyticsService.getSchools(query, page);
          case ViewLevel.classes:
            return this.analyticsService.getClassesWithFacets(query, page);
          case ViewLevel.students:
            return this.analyticsService.getUsers(
              query,
              page,
              'user_students_with_course_assigned_facet',
            );
          default:
            return combineLatest([of([]), of(0), of({})]);
        }
      }),
      take(1),
    );
  }

  changeStudentAssignment(
    student: StudentAnalytics,
    courses: Course[],
  ): Observable<ChangeAssignmentOutput> {
    const assignmentsToUnassign = courses.filter((c) => !c.assigned).flatMap((c) => c.assignments);

    const assignmentsToAssign = courses
      .filter((c) => c.assigned)
      .filter((c) => c.product !== Product.wacs)
      .map((c) => {
        if (c.assignments && c.assignments[0]) {
          return this.changeSessionTime(c.assignments[0], c);
        }
        return this.createNewAssignment(c, student);
      });

    if (isEmpty(assignmentsToUnassign) && isEmpty(assignmentsToAssign)) {
      return of({ successes: [] });
    }
    return this.assignmentsService.assignmentCreateUpdateDelete(
      assignmentsToAssign,
      assignmentsToUnassign,
      student.schools[0].uuid,
    );
  }

  changeClassAssignment(
    students: StudentAnalytics[],
    courses: Course[],
  ): Observable<ChangeAssignmentOutput> {
    const assignmentsToUnassign = courses.filter((c) => !c.assigned).flatMap((c) => c.assignments);

    const assignmentsToAssign = courses
      .filter((c) => c.assigned)
      .filter((c) => c.product !== Product.wacs)
      .flatMap((c) => {
        if (c.assignments && c.assignments.length > 0) {
          return c.assignments.map((a) => this.changeSessionTime(a, c));
        }
        const nonCerdepStudents = students.filter(
          (student) =>
            !student.metadata ||
            !student.metadata.find((metadata) => metadata.key === 'cerdep') ||
            student.metadata.find((metadata) => metadata.key === 'cerdep').value === 'false',
        );
        return nonCerdepStudents.map((student) => this.createNewAssignment(c, student));
      }) as AssignmentResponse[];

    if (isEmpty(assignmentsToUnassign) && isEmpty(assignmentsToAssign)) {
      return of({ successes: [] });
    }

    return this.assignmentsService.assignmentCreateUpdateDelete(
      assignmentsToAssign,
      assignmentsToUnassign,
      students[0].schools[0].uuid,
      false,
    );
  }

  getGoals(organization: string, entity: string): Observable<WelGoal[]> {
    return forkJoin([
      this.getGoalsForProduct(organization, entity, Product.erp),
      this.getGoalsForProduct(organization, entity, Product.ems),
      this.getGoalsForProduct(organization, entity, Product.smartstart),
    ]);
  }

  getGradeGoals(
    organization: string,
    entity: string,
    grade: string,
    product: Product,
  ): Observable<{ [key: string]: WelGoal[] }> {
    return forkJoin([
      this.getGradeGoalsForProduct(organization, entity, grade, product).pipe(
        map<WelGoal, { [key: string]: WelGoal[] }>((goal) => ({
          [`${product}_${grade}`]: [goal].map((g: WelGoal) => ({
            ...g,
            days: Math.round(g.playtimeWeek / g.playtimeDay),
          })),
        })),
      ),
    ]).pipe(map((val) => val.reduce((acc, curr) => ({ ...acc, ...curr }), {})));
  }

  saveGradeGoal(goal: WelGoal): Observable<WelGoal> {
    return this.apiGatewayService
      .put<WelGoal>(
        `achievement-v3/v3/wel/orgs/${goal.organizationKey}/products/${goal.productKey}/entities/${goal.entityKey}/grades/${goal.gradeKey}/goals`,
        {
          levelTargetKey: goal.levelTargetKey,
          playtimeWeek: goal.playtimeWeek,
          playtimeDay: goal.playtimeDay,
        },
        'welGoals',
      )
      .pipe(map((g) => ({ ...g, days: Math.round(g.playtimeWeek / g.playtimeDay) })));
  }

  saveGoal(organization: string, entity: string, goal: WelGoal): Observable<unknown> {
    return this.apiGatewayService.put(
      `achievement-v3/v3/wel/orgs/${organization}/products/${goal.productKey}/entities/${entity}/goals`,
      omit(goal, 'playtimeSchoolYear'),
      'welGoals',
    );
  }

  getTargets(): Observable<MetricTarget[][]> {
    return forkJoin([
      this.getTargetsForProduct(Product.erp),
      this.getTargetsForProduct(Product.ems),
      this.getTargetsForProduct(Product.smartstart),
    ]);
  }

  getOrgLocations(): Observable<ProductLocation[]> {
    return forkJoin([
      this.getLocationsForProduct(Product.erp),
      this.getLocationsForProduct(Product.ems),
    ]);
  }

  getStudentLocation(
    organization: string,
    entity: string,
    products: { erp: boolean; ems: boolean },
  ): Observable<(ProductLocation | {})[]> {
    return forkJoin([
      products.erp ? this.getStudentLocationsForProduct(organization, entity, Product.erp) : of({}),
      products.ems ? this.getStudentLocationsForProduct(organization, entity, Product.ems) : of({}),
    ]);
  }

  saveLocation(organization: string, entity: string, location: LevelLocation, studentName: string) {
    const { product, levelId, locationId } = location;
    return this.apiGatewayService
      .post(
        `wel-event-bridge/v2/sequencer/orgs/${organization}/students/${entity}/products/${product}/levels/${levelId}/locations/${locationId}`,
        {},
      )
      .pipe(
        catchError(({ error }) => {
          if (error?.description?.includes('is currently in a session')) {
            return of({ error: `${studentName} is currently in a session.` });
          } else {
            if (error?.description?.includes('is not assigned to product')) {
              return of({ error: `${studentName} is not assigned to this product.` });
            }
          }
          return of({ error: 'Changing the student location failed. Please try again later.' });
        }),
      );
  }

  getLock(organization: string, entity: string | string[]): Observable<{ [key: string]: boolean }> {
    const entities: string[] = isArray(entity) ? <string[]>entity : [<string>entity];
    return forkJoin(
      entities.map((entity) =>
        this.apiGatewayService
          .get<{
            locked: boolean;
          }>(`achievement-v3/v3/orgs/${organization}/entities/${entity}/locks`)
          .pipe(
            catchError(() => of({ locked: true })),
            map((response) => ({ [`${organization}_${entity}`]: response.locked })),
          ),
      ),
    ).pipe(
      mergeMap((res) => res),
      reduce((acc, curr) => ({ ...acc, ...curr }), {}),
    );
  }

  updateLock(organization: string, entity: string, locked: boolean): Observable<void> {
    return this.withUser().pipe(
      mergeMap((user) =>
        this.apiGatewayService.put<void>(
          `achievement-v3/v3/orgs/${organization}/entities/${entity}/locks`,
          { locked, userName: user.name, userRole: user.roles.join(',') },
          'entityLocks',
        ),
      ),
    );
  }

  private removeUnassignedStudents(selectedFacets: Facets): Facets {
    return {
      ...selectedFacets,
      unassignedStudents: [
        {
          value: 'false',
          display: 'Show Assigned',
        },
      ],
    };
  }

  private changeSessionTime(assignment: AssignmentResponse, course: Course): AssignmentResponse {
    return {
      ...assignment,
      metadata: { ...assignment.metadata, sessionTime: course.sessionTime * 60 },
    };
  }

  private createNewAssignment(course: Course, student: StudentAnalytics): AssignmentResponse {
    return {
      organization: student.organization.uuid,
      user: student.uuid,
      documentId: documentId(course.product),
      metadata: {
        products: [course.product],
        sessionTime: course.sessionTime * 60,
      },
      school: student.schools[0].uuid,
    } as AssignmentResponse;
  }

  private getGradeGoalsForProduct(
    organization: string,
    entity: string,
    grade: string,
    product: Product,
  ): Observable<WelGoal> {
    return this.apiGatewayService.get<WelGoal>(
      `achievement-v3/v3/wel/orgs/${organization}/products/${product}/entities/${entity}/grades/${grade}/goals`,
    );
  }

  getGoalsForProduct(organization: string, entity: string, product: Product): Observable<WelGoal> {
    return this.apiGatewayService
      .get(
        `achievement-v3/v3/wel/orgs/${organization}/products/${product}/entities/${entity}/goals`,
      )
      .pipe(
        map((goal: WelGoal) => ({
          ...goal,
          days: Math.round(goal.playtimeWeek / goal.playtimeDay),
        })),
      );
  }

  getTargetsForProduct(product: Product): Observable<MetricTarget[]> {
    return this.apiGatewayService.get(
      `achievement-v3/v3/products/${product}/metrics/LEVEL/targets`,
    );
  }

  private getLocationsForProduct(product: Product): Observable<ProductLocation> {
    return this.withUser().pipe(
      mergeMap((user) =>
        this.apiGatewayService.get<ProductLocation>(
          `wel-event-bridge/v2/sequencer/orgs/${user.organization}/products/${product}/locations`,
        ),
      ),
    );
  }

  private getStudentLocationsForProduct(
    organization: string,
    entity: string,
    product: Product,
  ): Observable<ProductLocation | {}> {
    return this.apiGatewayService
      .get(
        `wel-event-bridge/v2/sequencer/orgs/${organization}/students/${entity}/products/${product}/locations`,
      )
      .pipe(catchError(() => of({})));
  }
}
